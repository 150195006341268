<mat-dialog-content>
  <h3>{{ message }}</h3>
  <span>{{ bodyMessage }}</span>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button
    mat-raised-button
    class="fleetflexButton"
    (click)="onConfirmClick()"
    tabindex="1">
    {{ confirmButtonText }}
  </button>
  <button
    mat-raised-button
    mat-dialog-close
    tabindex="-1"
    class="fleetflexButton">
    {{ cancelButtonText }}
  </button>
</mat-dialog-actions>
