import { ITag } from './tag.model';
import { ICustomFiled } from './customFiled.model';
import { Driver } from './driver.model';
import * as _ from 'lodash';
import { DeliveryPointModel, IDeliveryPoint } from './deliveryPoint.model';
import * as moment from 'moment';

export interface IRouteDeliveryPoint {
  _id: String;
  companyId: String;
  updatedAt: Date;
  createdAt: Date;
  documentedAt: Date;
  type: 'delivery' | 'task';
  comments?: String;
  files?: String[];
  referralGuides?: {
    referralGuide: String;
    comments: String;
  }[];
  signature?: String;
  status?: 'completed' | 'incomplete' | 'not-completed' | 'rejected' | null;
  receiverId?: String;
  receiverName?: String;
  isDelivered?: boolean;
  latitude?: number;
  longitude?: number;
  address?: String;
  tags?: ITag[] | String[];
  custom_fields: IDeliveryCustomField[];
  phoneNumber?: String;
  contactName?: String;
  userId?: Driver;
  deliveryPoint: IDeliveryPoint;
}
interface IDeliveryCustomField {
  fieldId: ICustomFiled | String | any;
  label: String;
  unit: String;
  value: any;
}

export class RouteDeliveryPointModel implements IRouteDeliveryPoint {
  _id: String;
  address: String;
  comments: String;
  companyId: String;
  contactName: String;
  createdAt: Date;
  custom_fields: IDeliveryCustomField[];
  documentedAt: Date;
  files: String[];
  isDelivered: boolean;
  latitude: number;
  longitude: number;
  phoneNumber: String;
  receiverId: String;
  receiverName: String;
  referralGuides: { referralGuide: String; comments: String }[];
  signature: String;
  status: 'completed' | 'incomplete' | 'not-completed' | 'rejected' | null;
  tags: ITag[] | String[];
  type: 'delivery' | 'task';
  updatedAt: Date;
  userId?: Driver;
  driver?: Driver;
  deliveryPoint: DeliveryPointModel;

  // UI variables
  checked?: boolean;
  routeName?: string;
  time: string;
  timeLabel: string;
  constructor(
    id?: String,
    address?: String,
    comments?: String,
    companyId?: String,
    contactName?: String,
    createdAt?: Date,
    custom_fields?: IDeliveryCustomField[],
    documentedAt?: Date,
    files?: String[],
    isDelivered?: boolean,
    latitude?: number,
    longitude?: number,
    phoneNumber?: String,
    receiverId?: String,
    receiverName?: String,
    referralGuides?: { referralGuide: String; comments: String }[],
    signature?: String,
    status?: 'completed' | 'incomplete' | 'not-completed' | 'rejected' | null,
    tags?: ITag[] | String[],
    type?: 'delivery' | 'task',
    updatedAt?: Date,
    userId?: Driver,
    driver?: Driver,
    deliveryPoint?: DeliveryPointModel
  ) {
    this._id = id;
    this.userId = Driver.fromMap(userId);
    this.driver = Driver.fromMap(driver);
    this.address = address;
    this.comments = comments;
    this.companyId = companyId;
    this.contactName = contactName;
    this.createdAt = createdAt;
    this.custom_fields = custom_fields;
    this.documentedAt = documentedAt;
    this.files = files;
    this.isDelivered = isDelivered;
    this.latitude = latitude;
    this.longitude = longitude;
    this.phoneNumber = phoneNumber;
    this.receiverId = receiverId;
    this.receiverName = receiverName;
    this.referralGuides = referralGuides;
    this.signature = signature;
    this.status = status;
    this.tags = tags;
    this.type = type;
    this.updatedAt = updatedAt;
    this.deliveryPoint = DeliveryPointModel.fromMap(deliveryPoint);
  }
  static fromMap(map: any): RouteDeliveryPointModel {
    const routeDeliveryPoint: RouteDeliveryPointModel = Object.assign(
      new RouteDeliveryPointModel(),
      map
    );
    routeDeliveryPoint.deliveryPoint = DeliveryPointModel.fromMap(
      routeDeliveryPoint['deliveryPoint']
    );
    if (map['userId'] || map['driver'])
      routeDeliveryPoint.userId = routeDeliveryPoint.driver = Driver.fromMap(
        map['userId'] || map['driver']
      );
    return routeDeliveryPoint;
  }
  static fromListMap(list: Array<any>): Array<RouteDeliveryPointModel> {
    return list.map(x => this.fromMap(x));
  }
  static getWindowsTime(list: RouteDeliveryPointModel[]) {
    const documenteds = list.filter(rdp => rdp.documentedAt);
    if (!documenteds.length)
      throw new Error('No documented points to create time windows');
    documenteds.sort((a, b) => moment(a.documentedAt).diff(b.documentedAt));
    const startDate = moment(_.first(documenteds).documentedAt).format(
      'YYYY-MM-DDTHH:mm'
    );
    const endDate = moment(_.last(documenteds).documentedAt).format(
      'YYYY-MM-DDTHH:mm'
    );
    return { startDate, endDate };
  }

  /**
   * Return latitude and longitude string representation
   *
   * @param separator - String separator between latitude and longitude
   * @returns "latiude{{separtator}}longitude"
   */
  getCoordinatesStr(separator = ','): string {
    return `${this.latitude.toFixed(5)}${separator}${this.longitude.toFixed(
      5
    )}`;
  }
  get isFastRegistry() {
    return this.type === 'task';
  }
  get isDocumented(): boolean {
    return !_.isNil(this.documentedAt);
  }

  valueDocumentedAt(): number {
    return moment(this.documentedAt).valueOf();
  }
  strDocumentedAt(format: string): string {
    return this.documentedAt
      ? moment(this.documentedAt).format(format || 'HH:mm')
      : '';
  }

  getIcon(outline: true): string {
    switch (this.status) {
      case 'completed':
        return outline ? 'check' : 'check_circle';
      case 'incomplete':
      case 'not-completed':
        return outline ? 'error_outline' : 'error_outline';
      case 'rejected':
        return outline ? 'not_interested' : 'cancel';
      default:
        return '';
    }
  }
  getIconStyle() {
    switch (this.status) {
      case 'completed':
        return { color: '#4caf50' };
      case 'incomplete':
      case 'not-completed':
        return { color: '#f5a623' };
      case 'rejected':
        return { color: '#d32f2f' };
      default:
        return { color: 'black' };
    }
  }

  getClass(): string {
    switch (this.status) {
      case 'completed':
        return 'completed';
      case 'incomplete':
      case 'not-completed':
        return 'incomplete';
      case 'rejected':
        return 'warning';
    }
  }

  getStyle() {
    let color = 'black';
    switch (this.status) {
      case 'completed':
        color = 'green';
        break;
      case 'incomplete':
      case 'not-completed':
        color = 'yellow';
        break;
      case 'rejected':
        color = 'red';
        break;
    }
    return {
      color: color,
    };
  }

  isOutOfFence() {
    return (
      RouteDeliveryPointModel.calculateDistance({
        latitudeX: this.deliveryPoint.latitude,
        longitudeX: this.deliveryPoint.longitude,
        latitudeY: this.latitude,
        longitudeY: this.longitude,
      }) > 200
    );
  }

  getMapUrl(provider: 'google' = 'google') {
    switch (provider) {
      case 'google':
        // eslint-disable-next-line no-case-declarations
        const url = 'https://www.google.com/maps/search/';
        return `${url}${this.latitude},${this.longitude}`;
    }
  }

  static calculateDistance({ latitudeX, longitudeX, latitudeY, longitudeY }) {
    /**
     * Convert degrees to Radians
     *
     * @param degrees degrres
     * @returns radians
     */
    function degreesToRadians(degrees: number): number {
      return (degrees * Math.PI) / 180;
    }
    const R = 6378137;
    const dLat = degreesToRadians(latitudeX - latitudeY);
    const dLong = degreesToRadians(longitudeX - longitudeY);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(degreesToRadians(latitudeX)) *
        Math.cos(degreesToRadians(latitudeY)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  }

  /**
   * Return RouteDeliveryPoint plain object
   *
   * @returns RouteDeliveryPoint plain object
   */
  toMap() {
    return _.chain({
      _id: this._id,
      companyId: this.companyId,
      status: this.status,
      documentedAt: this.documentedAt,
      deliveryPoint: this.deliveryPoint.toMap(),
      driver: this.driver.toMap(),
      signature: this.signature,
      files: this.files,
      receiverId: this.receiverId,
      receiverName: this.receiverName,
      longitude: this.longitude,
      latitude: this.latitude,
      address: this.address,
      type: this.type,
      referralGuides: this.referralGuides,
      tags: this.tags,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    })
      .omitBy(_.isNil)
      .value();
  }

  /**
   * Get referral guides value
   *
   * @returns Referral guides value
   */
  getReferralGuidesValue() {
    return (
      this.referralGuides?.map(element => {
        return element.referralGuide;
      }) || []
    );
  }
}
