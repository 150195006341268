import { baseConfig } from './base-config';

const base_url: string = 'https://api.fleetflex.io';
const _environment = {
  production: true,
  defaultRole: 'company',

  // client
  client_id: 'fleetflex',
  client_secret: 'ed1fbd1eb41a11e7abc4cec278b6b50a',
  grant_type: 'password',
  googleMapsApiKey: 'AIzaSyBIcGUApRZZTBdIm3AXJiXHCcrcKiA3R8s',

  // firebase
  firebase: {
    apiKey: 'AIzaSyCmQUYlJqVygg_crbf-nGtfE1bmcSV0mLo',
    authDomain: 'fleetflex-182515.firebaseapp.com',
    databaseURL: 'https://fleetflex-182515.firebaseio.com',
    projectId: 'fleetflex-182515',
    storageBucket: 'fleetflex-182515.appspot.com',
    messagingSenderId: '616139645617',
    appId: '1:616139645617:android:1b0cfa3c17cb4bf6',
  },
  // file uploads
  upload_provider: 'GoogleCloudSigned',
  upload_drivers_bucket: 'fleetflex-users-data',
  upload_vehicles_bucket: 'fleetflex-users-data',
  upload_company_bucket: 'fleetflex-users-data',
  upload_bucket_url: 'https://fleetflex-users-data.storage.googleapis.com',
  // mixpanel
  mixpanel_key: '7a8fa71b75115d9f3fd9eaa4764de9f8',

  // sentry
  sentry_key: 'https://e21e1b5ed13a4f1cbd3b61ab068b2762@sentry.io/1186087',

  // Google Analytics
  ga: {
    url: 'https://www.googletagmanager.com/gtag/js?id=UA-118019885-1',
    ua: 'UA-118019885-1',
  },
  // Google tag manager
  gtm: 'GTM-MZ36K25',

  // tawk chat
  tawk: 'https://embed.tawk.to/5b3ac6794af8e57442dc4668/default',
};

export const environment = Object.assign(_environment, baseConfig(base_url));
